import React, { FC, useEffect, useState } from "react";
import * as Yup from "yup";
import { InputNumber, InputPassword, InputText } from "../../../components";
import { translate } from "../../../languages";
import { Button, CreateAlert, InputWraper, useForm } from "../../../modules";
import { MainService, RequestMainService, UserService } from "../../../services";
import { ConfigService } from "../../../services/config/config.service";
import { withConfigWraper } from "../wraper";
import { InputToggleSwitch } from "../../../components/toggle-switch";
import { EConfigName } from "../../../types";

const bonusConfigPage: FC<any> = withConfigWraper(() => {

  const [registerBonusConfig, setRegisterBonusConfig] = useState<any>(null);
  const [depositBonus, setDepositBonus] = useState<any>(null);

  let handleOnClickRegisterBonusSwitch = async (value: boolean) => {
    const payload = {
      isActiveScan: false,
      shouldProcessWithdraw: false,
      reservedEth: 0,
      withdrawRequestExpiration: 0,
      serverConfigName: EConfigName.REGISTER_BONUS,
      value: JSON.stringify({
        ...registerBonusConfig,
        IS_ACTIVE: value,
      }),
    };
    await ConfigService.setConfig(payload).then((res) => {
      RequestMainService.get(`/admin/get-server-config`).then((res) => {
        let registerBonusConfig = res?.result?.find((x: any) => x.serverConfigName === EConfigName.REGISTER_BONUS);
        let depositBonusConfig = res?.result?.find((x: any) => x.serverConfigName === EConfigName.DEPOSIT_BONUS);
        setRegisterBonusConfig(JSON.parse(registerBonusConfig?.value));
        setDepositBonus(JSON.parse(depositBonusConfig?.value));
      });
    });
  };

  let handleOnClickFirstDeposit50Switch = async (value: boolean) => {
    const payload = {
      isActiveScan: false,
      shouldProcessWithdraw: false,
      reservedEth: 0,
      withdrawRequestExpiration: 0,
      serverConfigName: EConfigName.DEPOSIT_BONUS,
      value: JSON.stringify({
        ...depositBonus,
        FIRST_DEPOSIT50: {
          ...depositBonus?.['FIRST_DEPOSIT50'],
          IS_ACTIVE: value,
        },
      }),
    };
    await ConfigService.setConfig(payload).then((res) => {
      RequestMainService.get(`/admin/get-server-config`).then((res) => {
        let registerBonusConfig = res?.result?.find((x: any) => x.serverConfigName === EConfigName.REGISTER_BONUS);
        let depositBonusConfig = res?.result?.find((x: any) => x.serverConfigName === EConfigName.DEPOSIT_BONUS);
        setRegisterBonusConfig(JSON.parse(registerBonusConfig?.value));
        setDepositBonus(JSON.parse(depositBonusConfig?.value));
      });
    });
  };

  useEffect(() => {
    RequestMainService.get(`/admin/get-server-config`).then((res) => {
      let registerBonusConfig = res?.result?.find((x: any) => x.serverConfigName === EConfigName.REGISTER_BONUS);
      let depositBonusConfig = res?.result?.find((x: any) => x.serverConfigName === EConfigName.DEPOSIT_BONUS);
      setRegisterBonusConfig(JSON.parse(registerBonusConfig?.value));
      setDepositBonus(JSON.parse(depositBonusConfig?.value));
    });
  }, []);

  return (
    <div className="row">
      <div className="col-sm-6">
        <div className="mb16">
          <InputToggleSwitch
            onChange={(value) => handleOnClickRegisterBonusSwitch(value)}
            value={registerBonusConfig?.IS_ACTIVE}
            onTouched={() => false}
            label="Register Bonus"
            name=""
          />
        </div>
        <div className="mb16">
          <InputToggleSwitch
            onChange={(value) => handleOnClickFirstDeposit50Switch(value)}
            value={depositBonus?.['FIRST_DEPOSIT50']?.IS_ACTIVE}
            onTouched={() => false}
            label="First Deposit 50"
            name=""
          />
        </div>
      </div>
    </div>
  );
});

export default bonusConfigPage;
