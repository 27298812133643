import moment from "moment";
import React, { FC, useEffect, useState } from "react";
import XLSX from "xlsx";
import { TableFilterInputText, TableFilterRangeTimeInput } from "../../../components/table-filter-inputs";
import { TableFilterInputSelect } from "../../../components/table-filter-inputs/select";
import { getEnv } from "../../../configs";
import { getLocaleKey } from "../../../languages";
import { Button, ClassNames, CreateAlert, Icon, NumberUtils, Table } from "../../../modules";
import { AdminService } from "../../../services/admin";
import { GameManagementLayout } from "../components";
import CopyToClipboard from "react-copy-to-clipboard";

export const OrdersPage: FC = GameManagementLayout((props: any) => {
  let optionsTimeFilter = [
    { label: "This Day", value: "day" },
    { label: "This Week", value: "week" },
    { label: "This Month", value: "month" },
    { label: "Custom", value: "custom" },
  ];

  const [currentData, setCurrentData] = useState(null as any);
  // const [isShowTable, setIsShowTable] = useState<boolean>(true);
  const [params, setParams] = useState<any>();
  const [valueTimeFilter, setValueTimeFilter] = useState<any>();
  const [forceUpdateTable, setForceUpdateTable] = useState<boolean>(false);

  // const startOfMonth = moment()
  //   .subtract(1, "months")
  //   .startOf("month")
  //   .format("YYYY-MM-DD HH:mm:ss");
  // const endOfDay = moment().endOf("day").format("YYYY-MM-DD HH:mm:ss");

  return (
    <div className="page-game-orders-list">
      <div className="button-container">
        <Button
          disabled={!currentData}
          label="Export to Excel"
          buttonType="success"
          className="mb15"
          onClick={async () => {
            // const response = await AdminService.getListRequestClaim({
            //     numberOfTransactionsPerPage: 10000,
            //     page: 1,
            //     fromDate: startOfMonth, //'0',
            //     toDate: endOfDay //moment().toISOString()
            // });
            const response = await AdminService.getListGameOrders({
              ...params,
              page: 1,
              pageSize: 100000,
            });
            const data = [
              ["Game Platform", "Created Date", "Email", "Table Game", "Amount", "Result"],
              ...response.data.map((item: any) => {
                let row: any[] = [];
                row.push(item?.gamePlaform ?? "-");
                row.push(moment(item?.created).format("DD/MM/y HH:mm:ss") ?? "-");
                // row.push(item?.thirdPartyUid ?? "-");
                row.push(item?.email ?? "-");
                row.push(item?.tableName ?? "-");
                row.push(NumberUtils.toFormatNumber(item?.amount, +getEnv("NUMBER_DECIMAL_DISPLAY")) ?? "-");
                row.push(
                  item.status === "LOSE"
                    ? item.profit > 0
                      ? NumberUtils.toFormatNumber(item.amount - item.profit, +getEnv("NUMBER_DECIMAL_DISPLAY"))
                      : NumberUtils.toFormatNumber(item.amount, +getEnv("NUMBER_DECIMAL_DISPLAY"))
                    : item.status === "WIN"
                    ? NumberUtils.toFormatNumber(item.profit - item.amount, +getEnv("NUMBER_DECIMAL_DISPLAY"))
                    : NumberUtils.toFormatNumber(item.profit, +getEnv("NUMBER_DECIMAL_DISPLAY")) ?? "-"
                );
                return row;
              }),
            ];

            const ws = XLSX.utils.aoa_to_sheet(data);
            const wb = XLSX.utils.book_new();
            XLSX.utils.book_append_sheet(wb, ws, "SheetJS");

            const now = new Date();
            XLSX.writeFile(
              wb,
              `Game-Orders-List-${now.toLocaleDateString().replace(/\//g, "-")} ${now.toLocaleTimeString().replace(/:/g, "-")}.xlsx`,
              { type: "binary" }
            );
          }}
        />
        <div className="infor-container">
          <div className="total-orders">
            <div className="total-orders__icon">
              <Icon.TotalOrdersIcon />
            </div>
            <div className="total-orders__content">
              {new Intl.NumberFormat("en").format(currentData?.count ?? 0)}
              <span className="sub-text">&nbsp;TOTAL ORDERS</span>
            </div>
          </div>
          <div className="total-user">
            <div className="total-user__icon">
              <Icon.UserParticipationIcon />
            </div>
            <div className="total-user__content">
              {new Intl.NumberFormat("en").format(currentData?.totalUserOrder ?? 0)}
              <span className="sub-text">&nbsp;USERS PARTICIPATION</span>
            </div>
          </div>
          <div className="total-volume">
            <div className="total-volume__icon">
              <Icon.TotalVolumeIcon />
            </div>
            <div className="total-volume__content">
              {currentData?.totalAmount ? NumberUtils.toFormatNumber(currentData?.totalAmount, 8) : 0}
              <span className="sub-text">&nbsp;TOTAL VOLUME</span>
            </div>
          </div>
        </div>
        {/* <div>
                    {currentData ? (
                        <div>
                            <p>
                                Total orders:{" "}
                                {NumberUtils.toFormatNumber(currentData.count)}
                            </p>{" "}
                            <p>
                                Total user orders:{" "}
                                {NumberUtils.toFormatNumber(
                                    currentData.totalUserOrder
                                )}{" "}
                            </p>
                            <p>
                                Total volume:{" "}
                                {NumberUtils.toFormatNumber(
                                    currentData.totalAmount,
                                    8
                                )}
                            </p>
                        </div>
                    ) : null}
                </div> */}
      </div>

      <Table
        hasOrderColumn
        openFilter={true}
        hasSearchButton={true}
        className="overload"
        filters={
          valueTimeFilter === "custom"
            ? [
                {
                  name: "Game Plaform",
                  key: "gamePlaform",
                  input: TableFilterInputText,
                  defaultValue: params?.gamePlaform,
                },
                {
                  name: "Username Login",
                  key: "searchString",
                  input: TableFilterInputText,
                  defaultValue: params?.searchString,
                },
                {
                  name: "Table Game",
                  key: "tableName",
                  input: TableFilterInputText,
                  defaultValue: params?.tableName,
                },
                {
                  name: "Time",
                  key: "time",
                  input: (s) => (
                    <TableFilterInputSelect
                      {...s}
                      options={optionsTimeFilter}
                      value={valueTimeFilter}
                      onChange={(e) => {
                        s.onChange(e);
                        setValueTimeFilter(e?.time);
                        setForceUpdateTable((state) => !state);
                      }}
                      isClearable={true}
                    />
                  ),
                  // defaultValue: valueTimeFilter
                },
                {
                  name: "",
                  key: "created",
                  input: (e) => <TableFilterRangeTimeInput {...e} fromKey="fromDate" toKey="toDate" />,
                },
              ]
            : [
                {
                  name: "Game Plaform",
                  key: "gamePlaform",
                  input: TableFilterInputText,
                  defaultValue: params?.gamePlaform,
                },
                {
                  name: "Username Login",
                  key: "searchString",
                  input: TableFilterInputText,
                  defaultValue: params?.searchString,
                },
                {
                  name: "Table Game",
                  key: "tableName",
                  input: TableFilterInputText,
                  defaultValue: params?.tableName,
                },
                {
                  name: "Time",
                  key: "time",
                  input: (s) => (
                    <TableFilterInputSelect
                      {...s}
                      options={optionsTimeFilter}
                      value={valueTimeFilter}
                      onChange={(e) => {
                        s.onChange(e);
                        setValueTimeFilter(e?.time);
                        setForceUpdateTable((state) => !state);
                      }}
                      isClearable={true}
                    />
                  ),
                  // defaultValue: valueTimeFilter
                },
              ]
        }
        structure={[
          {
            name: "GAME PLAFORM",
            render: (item) => item?.gamePlaform ?? "-",
          },
          {
            name: "CREATED DATE",
            render: (item) => moment(item?.created).format("DD/MM/y HH:mm:ss") ?? "-",
          },
          // {
          //   name: "UID",
          //   render: (item) => item?.thirdPartyUid ?? "-",
          // },
          {
            name: "USER",
            render: (item) => (
              <div className="user">
                <span
                  className="user-clickable"
                >
                  <CopyToClipboard
                    text={item.userNameLogin}
                    onCopy={() => {
                      CreateAlert({
                        message: "Copied",
                        type: "success",
                      });
                    }}
                  >
                    <span className="copyable__icon"><Icon.Copy />{(item.userNameLogin != null && item.userNameLogin !== '') ? item.userNameLogin : 'N/A'} <br /></span>
                  </CopyToClipboard>
                  <CopyToClipboard
                    text={item.email}
                    onCopy={() => {
                      CreateAlert({
                        message: "Copied",
                        type: "success",
                      });
                    }}
                  >
                    <span className="copyable__icon"><Icon.Copy />{item.email} <br /></span>
                  </CopyToClipboard>
                </span>
              </div>
            ),
          },
          {
            name: "TABLE GAME",
            render: (item) => item?.tableName ?? "-",
          },
          {
            name: "AMOUNT",
            render: (item) => NumberUtils.toFormatNumber(item?.amount, 8) ?? "-",
          },
          {
            name: "RESULT",
            render: (item: any) => (
              <span className={`status status--${item?.status?.toLowerCase()}`}>
                {item.status === "LOSE"
                  ? item.profit > 0
                    ? NumberUtils.toFormatNumber(item.amount - item.profit, 8)
                    : NumberUtils.toFormatNumber(item.amount, 8)
                  : item.status === "WIN"
                  ? NumberUtils.toFormatNumber(item.profit - item.amount, 8)
                  : NumberUtils.toFormatNumber(item.profit, 8)}
              </span>
            ),
          },
        ]}
        fetchData={async (state) => {
          setParams(state);

          if (valueTimeFilter !== "custom") {
            delete state.fromDate;
            delete state.toDate;
          }
          if (state?.time === "day") {
            delete state.fromDate;
            delete state.toDate;
            state["fromDate"] = moment().startOf("day").toISOString();
            state["toDate"] = moment().endOf("day").toISOString();
          }
          if (state?.time === "week") {
            delete state.fromDate;
            delete state.toDate;
            state["fromDate"] = moment().startOf("isoWeek").toISOString();
            state["toDate"] = moment().endOf("isoWeek").toISOString();
          }
          if (state?.time === "month") {
            delete state.fromDate;
            delete state.toDate;
            state["fromDate"] = moment().startOf("month").toISOString();
            state["toDate"] = moment().endOf("month").toISOString();
          }

          if (state?.time === "") {
            delete state.fromDate;
            delete state.toDate;
          }

          if (state["fromDate"]) state["fromDate"] = moment(state["fromDate"]).toISOString();
          if (state["toDate"]) state["toDate"] = moment(state["toDate"]).toISOString();

          return AdminService.getListGameOrders({
            page: state.pageNumber,
            pageSize: state.limit,
            ...state,
          }).then((res: any) => {
            setCurrentData(res);
            return res;
          });
        }}
        forceUpdateTable={forceUpdateTable}
      />
    </div>
  );
});
