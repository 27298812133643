export enum ELocale {
  ENGLISH = "en-US",
  VIETNAM = "vi-VN",
  JAPAN = "ja-JP",
  THAILAN = "th-TH",
  INDONESIA = "id-ID",
  MALAYSIA = "ms-MY",
  CHINA = "zh-CN",
}

export interface IFirebaseConfig {
  apiKey: string;
  authDomain: string;
  databaseURL: string;
  projectId: string;
  storageBucket: string;
  messagingSenderId: string;
  appId: string;
  measurementId: string;
}

export enum EDeviceType {
  DESKTOP = "Desktop",
  MOBILE = "Mobile",
}

export enum EOrderStatus {
  OPEN = "OPEN",
  WIN = "WIN",
  LOSE = "LOSE",
  DRAW = "DRAW",
}

export enum EOrderOption {
  HIGHER = "HIGHER",
  LOWER = "LOWER",
}

export interface IOrder {
  orderId: number;
  userId: number;
  symbolId: number;
  coinId: number;
  amount: number;
  profit: number;
  status: EOrderStatus;
  option: EOrderOption;
  created: Date;
  modified: Date;
}

export enum ETypeApplication {
  DOC = "doc",
  DOCX = "docx",
  PDF = "pdf",
  JPG = "jpg",
  PNG = "png",
  JPEG = "jpeg",
}

export enum EConfigName {
  BSC_CONFIG = "BSC_CONFIG",
  WITHDRAW_LIMIT_CONFIG = "WITHDRAW_LIMIT_CONFIG",
  RUN_ADMIN_WITHDRAW_CONFIG = "RUN_ADMIN_WITHDRAW_CONFIG",
  EXCHANGE_GAME_CONFIG = 'EXCHANGE_GAME_CONFIG',
  USER = "USER",
  EXCHANGE_CONFIG = "EXCHANGE_CONFIG",
  AIRDROP_CONFIG = "AIRDROP_CONFIG",
  GG_CONFIG = "GG_CONFIG",
  QCASH_CONFIG = "QCASH_CONFIG",
  BLOCK_REGISTER_EMAIL_DOMAINS = "BLOCK_REGISTER_EMAIL_DOMAINS",
  CAPTCHA_CONFIG = 'CAPTCHA_CONFIG',
  AGENT_CONFIG = 'AGENT_CONFIG',
  COMPLETE_WITHDRAW='COMPLETE_WITHDRAW',
  USER_PROMOTION='USER_PROMOTION',
  DEPOSIT_BONUS = 'DEPOSIT_BONUS',
  IP_CHILD_CONFIG = 'IP_CHILD_CONFIG',
  DEPOSIT_PROMOTION = 'DEPOSIT_PROMOTION',
  TOKEN_STANDARD_CONFIG = 'TOKEN_STANDARD_CONFIG',
  REGISTER_BONUS = 'REGISTER_BONUS',
}