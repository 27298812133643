import React, { FC, useState } from "react";
import XLSX from "xlsx";
import * as Yup from "yup";
import { Icon, InputNumber, InputText } from "../../../components";
import { GeneralPopupCompnt } from "../../../components/general-popup-compnt";
import { PopupWraper } from "../../../components/popup";
import { TableFilterInputText, TableFilterRangeTimeInput } from "../../../components/table-filter-inputs";
import { getEnv } from "../../../configs";
import { translate } from "../../../languages";
import { Button, CreateAlert, InputWraper, NumberUtils, onError, Table, useForm } from "../../../modules";
import { UserService } from "../../../services";
import { AdminService } from "../../../services/admin";
import { useSelector } from "../../../store";
import { withFranchiseWraper } from "../wraper";
import { TransferMainWallet } from "../transfer-main-wallet";
import moment from "moment";
import CopyToClipboard from "react-copy-to-clipboard";

export const PageFranchiseTable = withFranchiseWraper(() => {
  const user = useSelector((state) => state.user);
  const [currentData, setCurrentData] = useState(null as any);
  const [currentUserSetLevel, setCurrentUserSetLevel] = useState<any>();
  const [isShowPopup, setIsShowPopup] = useState<boolean>(false);
  const [isShowTable, setIsShowTable] = useState<boolean>(true);
  const [dataRecovery, setDataRecovery] = useState<any>(null);
  const [openTransfer, setOpenTransfer] = useState<any>(null);
  const [forceUpdateTable, setForceUpdateTable] = useState(Math.random());
  const [params, setParams] = useState<any>();

  // const startOfMonth = moment().subtract(1, "months").startOf("month");
  // const endOfDay = moment().endOf("day");

  let structure = [
    {
      name: "USER",
      key: "email",
      render: (item:any) => {
        return (
          <div className="user">
            <span
              className="user-clickable"
              onClick={() => {
                setCurrentUserSetLevel(item);
                if (user?.userRole !== "AGENT") {
                  setIsShowPopup(true);
                }
              }}
            >
              <span style={{display: 'flex', alignItems: 'center'}}>
                <CopyToClipboard
                  text={item.userNameLogin}
                  onCopy={() => {
                    CreateAlert({
                      message: "Copied",
                      type: "success",
                    });
                  }}
                >
                  <span 
                    className="copyable__icon" 
                    onClick={(event) => {
                      event.stopPropagation();
                    }}
                  >
                    <Icon.Copy />
                  </span>
                </CopyToClipboard>
                {(item.userNameLogin != null && item.userNameLogin !== '') ? item.userNameLogin : 'N/A'} <br />
              </span>
              <span style={{display: 'flex', alignItems: 'center'}}>
                <CopyToClipboard
                  text={item.email}
                  onCopy={() => {
                    CreateAlert({
                      message: "Copied",
                      type: "success",
                    });
                  }}
                >
                  <span 
                    className="copyable__icon" 
                    onClick={(event) => {
                      event.stopPropagation();
                    }}
                  >
                    <Icon.Copy />
                  </span>
                </CopyToClipboard>
                {item.email} <br />
              </span>
            </span>
            {/* <span className="ip">IP: {item?.ip ?? "?"}</span> */}
          </div>
        );
      },
      isCopyable: true,
    },
    {
      name: "TOTAL VOLUME",
      key: "volume",
      render: (item:any) => {
        const value = +item.volume;
        return value ? NumberUtils.toFormatNumber(+item.volume, +getEnv("NUMBER_DECIMAL_DISPLAY")) : "0.00000000";
      },
      sort: {},
    },
    {
      name: "PROFIT",
      key: "profitVolume",
      render: (item:any) => {
        const value = +item.profitVolume;
        return value ? (
          <span className={item?.profitVolume === 0 ? "" : item?.profitVolume > 0 ? "profit-volume-green" : "profit-volume-red"}>
            {NumberUtils.toFormatNumber(+item?.profitVolume, +getEnv("NUMBER_DECIMAL_DISPLAY"))}
          </span>
        ) : (
          <span className="profit-volume-green">0.00000000</span>
        );
      },
      sort: {},
    },
    // {
    //     name: "Won volume",
    //     key: "wonVolume",
    //     className: "textSuccess",
    //     render: (item) => {
    //         return `$${(+item.wonVolume).toLocaleString(
    //             getLocaleKey(),
    //             {
    //                 maximumFractionDigits: 2,
    //                 minimumFractionDigits: 2,
    //             }
    //         )}`;
    //     },
    //     sort: {},
    // },
    // {
    //     name: "Lose volume",
    //     key: "lostVolume",
    //     className: "textDanger",
    //     render: (item) => {
    //         const value = +item.lostVolume;
    //         return value
    //             ? `$${(+item.lostVolume).toLocaleString(
    //                   getLocaleKey(),
    //                   {
    //                       maximumFractionDigits: 2,
    //                       minimumFractionDigits: 2,
    //                   }
    //               )}`
    //             : "$0.00";
    //     },
    //     sort: {},
    // },
    // {
    //     name: "Draw volume",
    //     key: "drawVolume",
    //     className: "textWarning",
    //     render: (item) => {
    //         const value = +item.drawVolume;
    //         return value
    //             ? `$${(+item.drawVolume).toLocaleString(
    //                   getLocaleKey(),
    //                   {
    //                       maximumFractionDigits: 2,
    //                       minimumFractionDigits: 2,
    //                   }
    //               )}`
    //             : "$0.00";
    //     },
    //     sort: {},
    // },
    // {
    //   name: "Deposit",
    //   render: (item) => {
    //     return (
    //       <span>
    //         ETH:{" "}
    //         {(+item.depositEth).toLocaleString(getLocaleKey(), {
    //           maximumFractionDigits: 8,
    //           minimumFractionDigits: 8
    //         })}{" "}
    //         <br />
    //         USDT:{" "}
    //         {(+item.depositUsdt).toLocaleString(getLocaleKey(), {
    //           maximumFractionDigits: 8,
    //           minimumFractionDigits: 8
    //         })}
    //       </span>
    //     );
    //   },
    // },
    // {
    //     name: "Withdraw",
    //     render: (item) => {
    //         return (
    //             <span>
    //                 USDT:{" "}
    //                 {NumberUtils.toFormatNumber(+item?.withdrawUsdt, 4)}
    //             </span>
    //         );
    //     },
    // },
    {
      name: "BALANCE",
      key: "balanceUsdt",
      render: (item:any) => {
        return (
          <span>
            {/* MEGADAO:{" "}
            {NumberUtils.toFormatNumber(
              +item?.balanceGp2,
              +getEnv("NUMBER_DECIMAL_DISPLAY")
            )}{" "}
            <br /> */}
            USDT: {NumberUtils.toFormatNumber(+item?.balanceUsdt, +getEnv("NUMBER_DECIMAL_DISPLAY"))}
            {"  "}
            {user.userRole === "SUPER_ADMIN" && <span className="recovery" onClick={() => {if (user.userRole !== "SUPER_ADMIN") return; setDataRecovery(item)}}>[Recovery]</span>}
          
          </span>
        );
      },
      sort: {},
    },
    {
      name: "RANK COMMISSION",
      key: "rankCommission",
      render: (item:any) => {
        return NumberUtils.toFormatNumber(+item?.rankCommission, +getEnv("NUMBER_DECIMAL_DISPLAY"));
      },
      sort: {},
    },
    // {
    //   name: "Direct Commission",
    //   key: "directCommission",
    //   render: (item) => {
    //     return `${(+item.directCommission).toLocaleString(
    //       getLocaleKey(),
    //       { maximumFractionDigits: 2, minimumFractionDigits: 2 }
    //     )}`;
    //   },
    //   sort: {},
    // },
    // {
    //   name: "CASHBACK",
    //   key: "cashBack",
    //   render: (item) => {
    //     return NumberUtils.toFormatNumber(+item?.cashBack, +getEnv("NUMBER_DECIMAL_DISPLAY"));
    //   },
    //   sort: {},
    // },
    // {
    //   name: "Exchange In",
    //   key: "exchangeIn",
    //   render: (item) => {
    //     const value = +item.exchangeIn;
    //     return value
    //       ? NumberUtils.toFormatNumber(+item?.exchangeIn, +getEnv("NUMBER_DECIMAL_DISPLAY"))
    //       : "0.00000000";
    //   },
    //   sort: {},
    // },
    // {
    //   name: "Exchange Out",
    //   key: "exchangeOut",
    //   render: (item) => {
    //     const value = +item.exchangeOut;
    //     return value
    //       ? NumberUtils.toFormatNumber(
    //           +item?.exchangeOut,
    //           +getEnv("NUMBER_DECIMAL_DISPLAY")
    //         )
    //       : "0.00000000";
    //   },
    //   sort: {},
    // },
    // {
    //   name: "Level",
    //   render: (item) => (
    //     <img className="level" src={`/assets/images/rank/rank_${item?.rank}.png`} alt="" />
    //   ),
    //   sort: {},
    // },
    // {
    //   name: "Agency Count",
    //   key: "agencyCount",
    //   render: (item) => {
    //     return `${(+item.agencyCount).toLocaleString(getLocaleKey(), {
    //       maximumFractionDigits: 2,
    //       minimumFractionDigits: 2
    //     })}`;
    //   },
    //   sort: {},
    // },
    // {
    //   name: "IsReciprocal",
    //   key: "isNotCommission",
    //   render: (item) => {
    //     return !item?.isNotCommission ? '' : 'Yes'
    //   }
    // },
    // {
    //   name: "isEXCHANGE",
    //   key: "isExchange",
    //   render: (item) => {
    //     return !item?.isExchange ? '' : 'Yes'
    //   }
    // },
    // {
    //   name: "VOL.R.T.WITHDRAW",
    //   key: "volumeRequiredToWithdraw",
    //   render: (item) => NumberUtils.toFormatNumber(+item?.volumeRequiredToWithdraw, +getEnv("NUMBER_DECIMAL_DISPLAY")),
    // },
    {
      name: "Transfer Main Wallet",
      key: "transferMainWallet",
      render: (item:any) => {
        return (
          <div className="action">
            <div
              className="action__plus"
              onClick={() =>
                setOpenTransfer({
                  email: item?.email,
                  type: "plus",
                })
              }
            >
              <Icon.Plus />
            </div>
          </div>
        );
      },
    },
  ]

  return (
    <div className="PageFranchiseTable">
      <Button
        disabled={!currentData}
        label="Export to Excel"
        buttonType="success"
        className="mb15"
        onClick={async () => {
          const response = await AdminService.getListAffilateTable({
            pageSize: 100000,
            page: 1,
            ...params,
          });
          const data = [
            [
              "Email",
              "Name",
              "Total Volume",
              "Profit",
              // "Withdraw",
              "USDT Balance",
              // "MegaDAO Balance",
              "Rank Commission",
              // "Direct Commission",
              // "Cashback",
              // "Exchange In",
              // "Exchange Out",
              // "Level",
              // "Agency Count",
              // "IsReciprocal",
              // "IsExchange",
              // "Vol.R.T.Withdraw"
            ],
            ...response.data.map((item: any) => {
              //let ParseIP = item?.ip ? `IP: ${item?.ip}` : `IP: ?`;
              let row: any[] = [];
              row.push(item?.email);
              row.push(item?.userNameLogin);
              row.push(item?.volume ? NumberUtils.toFormatNumber(+item?.volume, +getEnv("NUMBER_DECIMAL_DISPLAY")) : `0.00000000`);
              row.push(item?.profitVolume ? NumberUtils.toFormatNumber(+item?.profitVolume, +getEnv("NUMBER_DECIMAL_DISPLAY")) : `0.00000000`);
              // row.push(`USDT: ${item?.withdrawUsdt ? NumberUtils.toFormatNumber(+item?.withdrawUsdt, 4) : `0.00000000`}`);

              row.push(`${item?.balanceUsdt ? NumberUtils.toFormatNumber(+item?.balanceUsdt, +getEnv("NUMBER_DECIMAL_DISPLAY")) : `0.00000000`}`);

              // row.push(`${item?.balanceGp2 ? NumberUtils.toFormatNumber(+item?.balanceGp2, +getEnv("NUMBER_DECIMAL_DISPLAY")) : `0.00000000`}`);

              row.push(item?.rankCommission ? NumberUtils.toFormatNumber(+item?.rankCommission, +getEnv("NUMBER_DECIMAL_DISPLAY")) : `0.00000000`);
              // row.push(item?.cashBack ? NumberUtils.toFormatNumber(+item?.cashBack, +getEnv("NUMBER_DECIMAL_DISPLAY")) : `0.00000000`);
              // row.push(
              //   item?.exchangeIn
              //     ? NumberUtils.toFormatNumber(+item?.exchangeIn, +getEnv("NUMBER_DECIMAL_DISPLAY"))
              //     : `0.00000000`
              // );
              // row.push(
              //   item?.exchangeOut
              //     ? NumberUtils.toFormatNumber(
              //         +item?.exchangeOut,
              //         +getEnv("NUMBER_DECIMAL_DISPLAY")
              //       )
              //     : `0.00000000`
              // );
              // row.push(item?.rank);
              // row.push(item?.isNotCommission === true ? 'Yes' : '');
              // row.push(item?.isExchange === true ? 'Yes' : '');
              // row.push(item?.volumeRequiredToWithdraw ? NumberUtils.toFormatNumber(+item?.volumeRequiredToWithdraw, +getEnv("NUMBER_DECIMAL_DISPLAY")) : `0.00000000`);

              return row;
            }),
          ];

          const ws = XLSX.utils.aoa_to_sheet(data);
          const wb = XLSX.utils.book_new();
          XLSX.utils.book_append_sheet(wb, ws, "SheetJS");

          const now = new Date();
          XLSX.writeFile(wb, `Franchise ${now.toLocaleDateString().replace(/\//g, "-")} ${now.toLocaleTimeString().replace(/:/g, "-")}.xlsx`, {
            type: "binary",
          });
        }}
      />

      {isShowTable && (
        <Table
          hasOrderColumn
          openFilter={true}
          hasSearchButton={true}
          className="overload"
          forceUpdateTable={forceUpdateTable}
          filters={[
            {
              name: "Username Login",
              key: "searchString",
              input: TableFilterInputText,
            },
            {
              name: "Date",
              key: "created",
              input: (e) => <TableFilterRangeTimeInput {...e} fromKey="fromDate" toKey="toDate" format={"MM/dd/y"} />,
              // defaultValue: {
              //     //fromDate: moment().subtract(1,"months"),
              //     //toDate: moment(),
              //     fromDate: startOfMonth,
              //     toDate: endOfDay,
              // },
            },
          ]}
          structure={structure}
          fetchData={async (params) => {
            // AdminService.getFranchiseUserReport(100, user.userId, params).then(
            //   (res) => {
            //     setCurrentData(res);
            //     return res;
            //   }
            // )
            setParams(params);
            return AdminService.getListAffilateTable({
              ...params,
              pageSize: params.limit,
              page: params.pageNumber,
            }).then((res) => {
              setCurrentData(res);
              return res;
            });
          }}
        />
      )}
      {isShowPopup && (
        <SetLevelPopup
          dataUser={currentUserSetLevel}
          onClose={(value: any) => setIsShowPopup(value)}
          onIsShowTable={(value: any) => setIsShowTable(value)}
        />
      )}
      {dataRecovery && (
        <PopupWraper className="recovery-popup" center title="Recovery" onClose={() => setDataRecovery(null)}>
          <RecoveryContentPopup data={dataRecovery} onClose={() => setDataRecovery(null)} />
        </PopupWraper>
      )}
      {openTransfer && (
        <PopupWraper center title={openTransfer?.type === "plus" ? "Receive Transfer" : "Send Transfer"} onClose={() => setOpenTransfer(null)}>
          <TransferMainWallet
            data={openTransfer}
            onFinish={() => {
              setForceUpdateTable(Math.random);
              setOpenTransfer(null);
            }}
          />
        </PopupWraper>
      )}
    </div>
  );
});

const RecoveryContentPopup: FC<any> = (props) => {
  const user = useSelector((state) => state.user);
  const [isShowPopupConfirm, setIsShowPopupConfirm] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const { handleSubmit, getInputProps, isSubmitting } = useForm({
    structure: [
      {
        name: "toEmail",
        validate: Yup.string().required(translate("must-be-provided")),
        defaultValue: getEnv("DEFAULT_RECOVERY_USDT_EMAIL"),
      },
    ],
    onSubmit: async (values) => {
      setIsShowPopupConfirm(true);
    },
  });

  let handleOnClickConfirm = () => {
    if (user.userRole !== "SUPER_ADMIN") return;
    setIsLoading(true);
    let payload = {
      fromEmails: props?.data?.email,
      toEmail: getInputProps("toEmail")?.value,
      coinId: 3,
    };
    AdminService.recoveryUSDT(payload)
      .then((res) => {
        return CreateAlert({ message: res?.message, type: "success" });
      })
      .catch((err) => {
        let messageTranslate = err?.message;
        if (err?.message?.search("NOT_FOUND") != -1) {
          messageTranslate = translate("{email}_NOT_FOUND", { email: messageTranslate.replace("_NOT_FOUND", "") });
        }
        if (err?.message?.search("AGENT_CANNOT_RECOVERY_BALANCE") != -1) {
          messageTranslate = translate("{email}_AGENT_CANNOT_RECOVERY_BALANCE", {
            email: messageTranslate.replace("_AGENT_CANNOT_RECOVERY_BALANCE", ""),
          });
        }
        if (err?.message?.search("NOT_IN_TREE_AGENT") != -1) {
          messageTranslate = translate("{email}_NOT_IN_TREE_AGENT", { email: messageTranslate.replace("_NOT_IN_TREE_AGENT", "") });
        }
        CreateAlert({ message: messageTranslate, type: "danger" });
      })
      .finally(() => {
        setIsLoading(false);
        setIsShowPopupConfirm(false);
        props?.onClose();
      });
  };

  return (
    <div className="recovery-content container-fluid">
      <div className="recovery-content__item row">
        <div className="col-5">
          <div className="recovery-content__item__label">Recovery From Email:</div>
        </div>
        <div className="col-7">
          <div className="recovery-content__item__value">{props?.data?.email ?? "unknown"}</div>
        </div>
      </div>
      <div className="recovery-content__item row">
        <div className="col-5">
          <div className="recovery-content__item__label">Recovery Amount:</div>
        </div>
        <div className="col-7">
          <div className="recovery-content__item__value">
            {props?.data?.balanceUsdt != null ? NumberUtils.toFormatNumber(+props?.data?.balanceUsdt, +getEnv("NUMBER_DECIMAL_DISPLAY")) : "unknown"}
            &nbsp;USDT
          </div>
        </div>
      </div>
      <div className="recovery-content__item row">
        <div className="col-12">
          <div className="recovery-content__item__label">
            <InputWraper label={"Recovery To Email"} inputProps={getInputProps("toEmail")} component={InputText} />
          </div>
        </div>
      </div>
      <div className="recovery-content__button">
        <Button
          // className="mb20"
          label="Update"
          onClick={handleSubmit}
          isMiddle
          buttonType="success"
          className="btnBan"
          type="submit"
          isLoading={isSubmitting}
        />
      </div>
      {isShowPopupConfirm && (
        <GeneralPopupCompnt
          onClose={() => {
            setIsShowPopupConfirm(false);
          }}
          onClickConfirm={() => handleOnClickConfirm()}
          textButton="OK"
          titlePopup={"Warning!"}
          messagePopup={[
            `Are you sure to recovery `,
            <span style={{ color: "#ff4c51" }}>
              {props?.data?.balanceUsdt != null
                ? NumberUtils.toFormatNumber(+props?.data?.balanceUsdt, +getEnv("NUMBER_DECIMAL_DISPLAY"))
                : "unknown"}
              &nbsp;USDT
            </span>,
            <br />,
            ` from `,
            <br />,
            <span style={{ color: "#4680FF" }}>{props?.data?.email ?? "unknown"}</span>,
            <br />,
            ` to `,
            <br />,
            <span style={{ color: "#4680FF" }}>{getInputProps("toEmail").value}</span>,
            `?`,
          ]}
          isLoading={isLoading}
        />
      )}
    </div>
  );
};

const SetLevelPopup: FC<any> = (props) => {
  const { handleSubmit, getInputProps, isSubmitting } = useForm({
    structure: [
      {
        name: "level",
        validate: Yup.number().required("Must be provided").integer("Number must be integer").max(11, "Maximum is 11").min(0, "Minimum is 1"),
      },
    ],
    onSubmit: async (values) => {
      props?.onIsShowTable(false);
      return AdminService.setLevel(props?.dataUser?.userId, values.level)
        .then(() => {
          return CreateAlert({
            type: "success",
            message: "Set level successfully.",
          });
        })
        .catch((err) => {
          return CreateAlert({ message: translate(err?.message), type: "danger" });
        })
        .finally(() => {
          props?.onIsShowTable(true);
          props?.onClose(false);
        });
    },
  });

  return (
    <PopupWraper title="Set level" onClose={() => props?.onClose(false)}>
      <div style={{ fontSize: "16px" }} className="container">
        <fieldset style={{ marginBottom: "32px" }}>
          <legend>Information:</legend>
          <div className="display-name">
            Username login: <span style={{ color: "#fff" }}>{props?.dataUser?.userNameLogin}</span>
          </div>
          <div className="email">
            Email: <span style={{ color: "#fff" }}>{props?.dataUser?.email}</span>
          </div>
          <div className="level">
            Current level: <span style={{ color: "#fff" }}>{props?.dataUser?.rank}</span>
          </div>
        </fieldset>
        <InputWraper inputProps={getInputProps("level")} renderInput={(props) => <InputNumber {...props} decima={0} />} />
        <Button onClick={handleSubmit} isLoading={isSubmitting} label="Set level" buttonType="success" type="submit" />
      </div>
    </PopupWraper>
  );
};

// export const SystemReportDetailPopup: FC<{
//     detail: any;
//     onClose: () => void;
// }> = (props) => {
//     if (!props.detail) return null;

//     const item = props.detail;

//     const data = [
//         {
//             label: "Start",
//             value: `${new Date(item.start).toLocaleDateString(getLocaleKey(), {
//                 hour12: false,
//             })} - ${new Date(item.start).toLocaleTimeString(getLocaleKey(), {
//                 hour12: false,
//             })}`,
//         },
//         {
//             label: "End",
//             value: `${new Date(item.end).toLocaleDateString(getLocaleKey(), {
//                 hour12: false,
//             })} - ${new Date(item.start).toLocaleTimeString(getLocaleKey(), {
//                 hour12: false,
//             })}`,
//         },
//         {
//             label: "Modified",
//             value: `${new Date(item.modified).toLocaleDateString(
//                 getLocaleKey(),
//                 {
//                     hour12: false,
//                 }
//             )} - ${new Date(item.start).toLocaleTimeString(getLocaleKey(), {
//                 hour12: false,
//             })}`,
//         },
//         {
//             label: "Total won/lose volume",
//             value: `${(+item.wonVolume).toLocaleString(getLocaleKey(), {
//                 maximumFractionDigits: 2,
//                 minimumFractionDigits: 2,
//             })}/${(+item.lostVolume).toLocaleString(getLocaleKey(), {
//                 maximumFractionDigits: 2,
//                 minimumFractionDigits: 2,
//             })}`,
//         },
//         {
//             label: "Hot Wallet",
//             value: (
//                 <span>
//                     USD:{" "}
//                     {(+item.hotWalletUsd).toLocaleString(getLocaleKey(), {
//                         maximumFractionDigits: 2,
//                         minimumFractionDigits: 2,
//                     })}{" "}
//                     <br />
//                     USDT:{" "}
//                     {(+item.hotWalletUsdt).toLocaleString(getLocaleKey(), {
//                         maximumFractionDigits: 8,
//                         minimumFractionDigits: 8,
//                     })}
//                 </span>
//             ),
//         },
//         {
//             label: "Deposit",
//             value: (
//                 <span>
//                     USD:{" "}
//                     {(+item.depositUsd).toLocaleString(getLocaleKey(), {
//                         maximumFractionDigits: 2,
//                         minimumFractionDigits: 2,
//                     })}{" "}
//                     <br />
//                     USDT:{" "}
//                     {(+item.depositUsdt).toLocaleString(getLocaleKey(), {
//                         maximumFractionDigits: 8,
//                         minimumFractionDigits: 8,
//                     })}
//                 </span>
//             ),
//         },
//         {
//             label: "Withdraw",
//             value: (
//                 <span>
//                     USD:{" "}
//                     {(+item.withdrawUsd).toLocaleString(getLocaleKey(), {
//                         maximumFractionDigits: 2,
//                         minimumFractionDigits: 2,
//                     })}{" "}
//                     <br />
//                     USDT:{" "}
//                     {(+item.withdrawUsdt).toLocaleString(getLocaleKey(), {
//                         maximumFractionDigits: 8,
//                         minimumFractionDigits: 8,
//                     })}
//                 </span>
//             ),
//         },
//         {
//             label: "User 's Balance",
//             value: (
//                 <span>
//                     USD:{" "}
//                     {(+item.userBalanceUsd).toLocaleString(getLocaleKey(), {
//                         maximumFractionDigits: 2,
//                         minimumFractionDigits: 2,
//                     })}{" "}
//                     <br />
//                     USDT:{" "}
//                     {(+item.userBalanceUsdt).toLocaleString(getLocaleKey(), {
//                         maximumFractionDigits: 8,
//                         minimumFractionDigits: 8,
//                     })}
//                 </span>
//             ),
//         },
//         {
//             label: "Safe",
//             value: (
//                 <span>
//                     USD:{" "}
//                     {(+item.safeUsd).toLocaleString(getLocaleKey(), {
//                         maximumFractionDigits: 2,
//                         minimumFractionDigits: 2,
//                     })}{" "}
//                     <br />
//                     USDT:{" "}
//                     {(+item.safeUsdt).toLocaleString(getLocaleKey(), {
//                         maximumFractionDigits: 8,
//                         minimumFractionDigits: 8,
//                     })}
//                 </span>
//             ),
//         },
//         {
//             label: "Login Count",
//             value: item.loginCount,
//         },
//         {
//             label: "Direct Commission",
//             value: item.directCommission,
//         },
//         {
//             label: "CashBack",
//             value: (+item.cashBack).toLocaleString(getLocaleKey(), {
//                 maximumFractionDigits: 2,
//                 minimumFractionDigits: 2,
//             }),
//         },
//         {
//             label: "New Package",
//             value: item.newPackage,
//         },
//         {
//             label: "Trading Commission",
//             value: item.rankCommission,
//         },
//     ];

//     return (
//         <div
//             className="SystemReport__DetailPopup"
//             id="SystemReport__DetailPopup"
//             onClick={(e: any) =>
//                 e.target.id === "SystemReport__DetailPopup"
//                     ? props.onClose()
//                     : null
//             }
//         >
//             <div className="box">
//                 <div className="boxTitle">
//                     <span>Detail Report</span>
//                     <div className="btnClose" onClick={() => props.onClose()}>
//                         <Icon.Close />
//                     </div>
//                 </div>
//                 <div className="content overload">
//                     <table>
//                         <tbody>
//                             {data.map((row, key) => (
//                                 <tr key={key}>
//                                     <td>{row.label}</td>
//                                     <td>{row.value}</td>
//                                 </tr>
//                             ))}
//                         </tbody>
//                     </table>
//                 </div>
//             </div>
//         </div>
//     );
// };
