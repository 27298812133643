import capitalize from "lodash/capitalize";
import moment from "moment";
import React, { memo, useState } from "react";
import { TableFilterInputText, TableFilterRangeTimeInput } from "../../../components/table-filter-inputs";
import { TableFilterInputSelect } from "../../../components/table-filter-inputs/select";
import { getEnv } from "../../../configs";
import { getLocaleKey, translate } from "../../../languages";
import { Button, CreateAlert, Icon, NumberUtils, ObjectUtils, Table } from "../../../modules";
import { BankService } from "../../../services";
import { AdminService } from "../../../services/admin";
import { useSelector } from "../../../store";
import { withTransactionWraper } from "../wraper";
import XLSX from "xlsx";
import CopyToClipboard from "react-copy-to-clipboard";

export enum ENetWork {
  BEP20 = "BEP20",
  TRC20 = "TRC20",
}

export const TransactionList = withTransactionWraper(
  memo(
    () => {
      //const last1MothDate = Date.now() - 1000 * 60 * 60 * 24 * 30;

      const startOfMonth = moment().startOf("month").format("YYYY/MM/DD HH:mm:ss");
      const endOfDay = moment().endOf("day").format("YYYY/MM/DD HH:mm:ss");

      const coinsState = useSelector((state) => state.coins);
      const coinsOptions = ObjectUtils.getIn(coinsState, "data", [], (arr) =>
        arr.map((item: any) => ({
          label: item.name,
          value: item.coinId,
        }))
      );
      const [params, setParams] = useState<any>();
      // const UsdId = 2;
      // const ButId = 5;
      // const DemoId = 4;
      // const Gp2Id = 5;

      let structure = [
        {
          name: "COIN",
          key: "coinId",
          className: "coin",
          render: (item: any) => {
            const coin = coinsOptions.find((v: any) => v.value === item.coinId);
            let coinAvatar;
            switch (coin?.value) {
              case 3: {
                coinAvatar = "usdt.png";
                break;
              }
              case 6: {
                coinAvatar = "usd.png";
                break;
              }
              case 8: {
                coinAvatar = "vlt.png";
                break;
              }
            }
            return (
              <>
                <img className="coin-avatar" src={`assets/images/coins/${coinAvatar}`} alt="" />
                <span className="coin-label">{ObjectUtils.getIn(coin, "label", "--")}</span>
              </>
            );
          },
        },
        {
          name: "TIME",
          key: "created",
          render: (item: any) => {
            const date = new Date(item.created);
            return <span>{moment(item?.created).format("DD/MM/y HH:mm:ss")}</span>;
          },
        },
        // {
        //   name: "EMAIL",
        //   key: "email",
        //   className: "email",
        //   isCopyable: true,
        // },
        {
          name: "USER",
          key: "email",
          render: (item:any) => {
            return (
              <div className="user">
                <span
                  className="user-clickable"
                >
                  <CopyToClipboard
                    text={item.userNameLogin}
                    onCopy={() => {
                      CreateAlert({
                        message: "Copied",
                        type: "success",
                      });
                    }}
                  >
                    <span className="copyable__icon"><Icon.Copy />{(item.userNameLogin != null && item.userNameLogin !== '') ? item.userNameLogin : 'N/A'} <br /></span>
                  </CopyToClipboard>
                  <CopyToClipboard
                    text={item.email}
                    onCopy={() => {
                      CreateAlert({
                        message: "Copied",
                        type: "success",
                      });
                    }}
                  >
                    <span className="copyable__icon"><Icon.Copy />{item.email} <br /></span>
                  </CopyToClipboard>
                </span>
              </div>
            );
          },
        },
        {
          name: "AMOUNT",
          key: "value",
          render: (item: any) => {
            const { balanceAfter, balanceBefore } = item;
            let isIncrease = false;
            if (balanceAfter > balanceBefore) isIncrease = true;
            return (
              <span className={isIncrease ? "textSuccess" : "textDanger"}>
                {isIncrease ? "+" : "-"}
                {NumberUtils.toFormatNumber(+item?.value, +getEnv("NUMBER_DECIMAL_DISPLAY"))}
              </span>
            );
          },
        },
        {
          name: "AFTER",
          key: "balanceAfter",
          render: (item: any) => NumberUtils.toFormatNumber(+item?.balanceAfter, +getEnv("NUMBER_DECIMAL_DISPLAY")),
        },
        {
          name: "TYPE",
          key: "transactionTypeId",
          render: (item: any) => {
            if (item.transactionType.name === "EXGAME_REFUND") return capitalize("EXPERIECE_GAME_REFUND".replace(/_/g, " "));
            if (item?.transactionType?.name === "AIRDROP_TOKEN_COMMISSION" || item?.transactionType?.name === "AIRDROP_TOKEN_COMMISSION_TO_USER")
              return capitalize(item.description.replace(/_/g, " "));
            return ObjectUtils.getIn(capitalize(item.transactionType.name.replace(/_/g, " ")), "", "--");
          },
          //render: (item) => ObjectUtils.getIn(item, 'transactionType.name', '--'),
        },
        // {
        //     name: translate("description"),
        //     key: "description",
        // },
        {
          name: "TxHASH",
          key: "transactionHash",
          render: (item: any) => {
            const hash = ObjectUtils.getIn(item, "transactionHash");
            if (hash) {
              switch (item.network) {
                case ENetWork.BEP20: {
                  return (
                    <a href={`${getEnv("BSC_SCAN")}${hash}`} target="__blank">
                      Click me
                    </a>
                  );
                }
                case ENetWork.TRC20: {
                  return (
                    <a href={`${getEnv("TRON_SCAN")}${hash}`} target="__blank">
                      Click me
                    </a>
                  );
                }
                default: {
                  return (
                    <></>
                    // <a href={`${getEnv("BSC_SCAN")}${hash}`} target="__blank">
                    //   Click me
                    // </a>
                  );
                }
              }
            }
            return "--";
          },
        },
      ];

      const handleExportExcel = async () => {
        return new Promise(async (resolve) => {
          try {
            const response = await AdminService.getListTransactions({
              page: 1,
              numberOfTransactionsPerPage: 10000,
              ...params,
            });

            const data = response.data;

            const fileHead = structure.map((v) => v.name);
            const dataExport = [
              fileHead,
              ...data.map((item: any) =>
                structure.map((column, index) => {
                  if (!column.key) return "";
                  if (column.key === "coinId") {
                    const coin = coinsOptions.find((v: any) => v.value === item.coinId);
                    return ObjectUtils.getIn(coin, "label", "--");
                  }
                  if (column.key === "created") return moment(item[column.key]).format("L HH:mm:ss");
                  if (column.key === "value") {
                    const { balanceAfter, balanceBefore } = item;
                    let isIncrease = false;
                    if (balanceAfter > balanceBefore) isIncrease = true;
                    return `${isIncrease ? "+" : "-"}${NumberUtils.toFormatNumber(+item?.value, +getEnv("NUMBER_DECIMAL_DISPLAY"))}`;
                  }
                  if (column.key === "balanceAfter") return NumberUtils.toFormatNumber(+item?.balanceAfter, +getEnv("NUMBER_DECIMAL_DISPLAY"));
                  if (column.key === "transactionTypeId") {
                    if (item.transactionType.name === "EXGAME_REFUND") return capitalize("EXPERIECE_GAME_REFUND".replace(/_/g, " "));
                    if (
                      item?.transactionType?.name === "AIRDROP_TOKEN_COMMISSION" ||
                      item?.transactionType?.name === "AIRDROP_TOKEN_COMMISSION_TO_USER"
                    )
                      return capitalize(item.description.replace(/_/g, " "));
                    return ObjectUtils.getIn(capitalize(item.transactionType.name.replace(/_/g, " ")), "", "--");
                  }

                  if (column.key === "transactionHash") {
                    const hash = ObjectUtils.getIn(item, "transactionHash");
                    if (hash) {
                      switch (item.network) {
                        case ENetWork.BEP20: {
                          return `${getEnv("BSC_SCAN")}${hash}`;
                        }
                        case ENetWork.TRC20: {
                          return `${getEnv("TRON_SCAN")}${hash}`;
                        }
                        default: {
                          return "";
                        }
                      }
                    }
                  }
                  return item[column.key];
                })
              ),
            ];

            const ws = XLSX.utils.aoa_to_sheet(dataExport);
            const wb = XLSX.utils.book_new();
            XLSX.utils.book_append_sheet(wb, ws, "SheetJS");

            const now = new Date();
            XLSX.writeFile(
              wb,
              `Transaction List ${now.toLocaleDateString().replace(/\//g, "-")} ${now.toLocaleTimeString().replace(/:/g, "-")}.xlsx`,
              { type: "binary" }
            );

            resolve(
              CreateAlert({
                type: "success",
                message: "Export data success.",
              })
            );
          } catch (error: any) {
            resolve(CreateAlert({ type: "danger", message: error.message }));
          }
        });
      };

      return (
        <div className="TransactionList">
          <Button className="mb20" label="Export to Excel" buttonType="success" onClick={handleExportExcel} />
          <Table
            hasOrderColumn
            hasSearchButton={true}
            itemPerPages={10}
            filters={[
              {
                name: "Coin",
                key: "coinId",
                input: (e) => <TableFilterInputSelect isClearable={false} {...e} options={coinsOptions.filter((item: any) => item.value !== 6)} />,
                defaultValue: 0,
              },
              {
                name: translate("time"),
                key: "created",
                input: (e) => <TableFilterRangeTimeInput {...e} fromKey="fromDate" toKey="toDate" />,
                defaultValue: {
                  fromDate: startOfMonth, //new Date(last1MothDate),
                  toDate: endOfDay, //new Date(),
                },
              },
              {
                name: "Username Login",
                key: "userNameLogin",
                input: TableFilterInputText,
              },
              // {
              //     name: "Amount",
              //     key: "amount",
              //     input: TableFilterInputNumber,
              // },
              {
                name: translate("type"),
                key: "transactionTypeId",
                input: (s) => <TableFilterInputSelect {...s} options={BankService.getTransactionTypeOptions()} />,
              },
            ]}
            structure={structure}
            fetchData={async (state) => {
              let params = { ...state };
              if (params["fromDate"]) params["fromDate"] = new Date(params["fromDate"]);
              if (params["toDate"]) params["toDate"] = new Date(params["toDate"]);
              setParams(params);
              return AdminService.getListTransactions({
                page: params.pageNumber,
                numberOfTransactionsPerPage: params.limit,
                ...params,
              });
            }}
          />
        </div>
      );
    },
    () => true
  )
);

